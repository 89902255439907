import create from 'zustand';
import {toast} from "react-toastify";

const defaultState = {
    isVisible: false,
    title: "",
    description: "",
    otpRequired: false,
    confirmText: 'Confirm',
    otpValue: "",
    onConfirm: () => {
    },
    onCancel: () => {
    }
}

const useModalStore = create((set, get) => ({
    ...defaultState,
    setOtpValue: (value) => set({otpValue: value}),
    clearModal: () => {
        set({
            ...defaultState
        })
    },
    hideModal: () => {
        set({
            isVisible: false
        })
    },
    getOtpValue: () => get().otpValue,
    isOtpRequired: () => get().otpRequired,
    setModal: ({isVisible, title, description, onConfirm, onCancel, otpRequired, confirmText}) =>
        set({
            isVisible: !!isVisible || false,
            title: title || "Confirm Action",
            description: description || "Are you sure you want to proceed?",
            otpRequired: otpRequired || false,
            confirmText: confirmText || "Confirm",
            otpValue: "",
            onConfirm: onConfirm || (() => {
            }),
            onCancel: onCancel || (() => {
                this.clearModal()
            })
        }),
}));

export default useModalStore;