import React from 'react';
import {Dialog} from '@headlessui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import useModalStore from "../store/modal";
import Verify2FA from "./Verify2FA";
import TextInput from "./TextInput";
import Button from "./Button";

function ConfirmationModal() {
    const {
        isVisible,
        title,
        description,
        onConfirm,
        onCancel,
        clearModal,
        hideModal,
        otpValue,
        setOtpValue,
        confirmText,
        otpRequired
    } = useModalStore();
    if (!isVisible) return null;

    const handleYes = () => {
        if (otpRequired && !otpValue) return;

        if (onConfirm) onConfirm();
        clearModal();
    };

    const handleNo = () => {
        if (onCancel) onCancel();
        clearModal();
    };

    return (
        <Dialog open={isVisible} onClose={handleNo} className="relative z-50">
            <Dialog.Overlay
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div
                                    className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold text-gray-900">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {otpRequired && <div className="flex mt-6 items-center justify-center">
                                <TextInput name="2fa-otp"
                                           label="Enter Two-Factor Authentication Code"
                                           id="2fa-otp" type="text"
                                           get={otpValue}
                                           set={value => {
                                               setOtpValue(value)
                                           }}/>
                            </div>}

                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={handleYes}
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            >
                                {confirmText}
                            </button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={handleNo}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );

    // return (
    //     isVisible && (
    //         <div className="modal">
    //             <div className="modal-content">
    //                 <h2>{title}</h2>
    //                 <p>{description}</p>
    //                 {otpRequired && (
    //                     <input
    //                         type="text"
    //                         placeholder="Enter OTP"
    //                         value={otpValue || ''}
    //                         onChange={(e) => setOtpValue && setOtpValue(e.target.value)}
    //                     />
    //                 )}
    //                 <button onClick={handleYes}>Yes</button>
    //                 <button onClick={handleNo}>No</button>
    //             </div>
    //         </div>
    //     )
    // );
}

export default ConfirmationModal;