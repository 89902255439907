import React, {useState} from 'react';
import useAuthStore from "../store/auth";
import Button from "./Button";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {apollo} from "../utils/apollo";
import {toast} from "react-toastify";
import {ACTIVATE_2FA, IS_2FA_ENABLED, VERIFY_2FA} from "../utils/graphql";
import {QRCodeSVG} from "qrcode.react";
import TextInput from "./TextInput";
import {cn} from "../utils/style";
import Verify2FA from "./Verify2FA";
import useModalStore from "../store/modal";
import use2FAStatus from "../hooks/use2FAStatus";

function Activate2FA() {
    const {user} = useAuthStore();
    const [secret, setSecret] = useState("");
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const queryClient = useQueryClient()

    const is2FAEnabled = use2FAStatus();

    const activate2FaMutation = useMutation({
        mutationFn: async ({otp}) => {
            const {data} = await apollo.mutate({
                mutation: ACTIVATE_2FA,
                variables: {
                    enable: !is2FAEnabled.data,
                    otp: otp
                },
            });
            return data?.activate2FA;
        },
        onSuccess: (data) => {
            if(data?.secret) setSecret(data.secret);
            if(data?.qrCodeUrl) setQrCodeUrl(data.qrCodeUrl);
            if (data?.success && data?.message) {
                toast.success(data?.message);
            } else if (!data?.success && data?.message) {
                toast.error(data?.message);
            }
            queryClient.invalidateQueries('is_2fa_enabled')
        },
    })

    return (
        <div
            className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <fieldset>
                <legend className="sr-only">Two Factor Authentication</legend>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                    <div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        Two Factor Authentication
                    </div>
                    <div className="mt-4 sm:col-span-2 sm:mt-0">
                        <div className="max-w-lg space-y-6">
                            <div className="relative flex gap-x-3">
                                <div>
                                    <Current2FAStatus mutation={activate2FaMutation} enabled={is2FAEnabled.data}/>

                                    {qrCodeUrl && <div>
                                        <p className="mb-3">Scan the QR Code below with Google Authenticator or
                                            Authy:</p>
                                        <QRCodeSVG height={250} width={250} value={qrCodeUrl}/>

                                        {secret && (
                                            <div className="mt-5">
                                                <p className="mb-3">Alternatively, you can manually enter this secret
                                                    key in your authenticator app:</p>
                                                <div className="p-3 bg-gray-100 rounded-md border text-gray-900">
                                                    <code>{secret}</code>
                                                </div>
                                            </div>
                                        )}

                                        <Verify2FA afterValidation={() => {
                                            setQrCodeUrl('')
                                            setSecret('')
                                        }}/>

                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}

function Current2FAStatus({enabled, mutation}) {
    const {setModal, clearModal, otpValue, otpRequired, getOtpValue, isOtpRequired, hideModal} = useModalStore();

    const handleAction = () => {
        setModal({
            isVisible: true,
            title: "Confirm Action",
            description: `Are you sure you want to ${enabled ? 'Disable' : 'Enable'} Two-Factor Authentication?`,
            otpRequired: enabled,
            confirmText: 'Confirm',
            onConfirm: () => {
                if (isOtpRequired() && !getOtpValue()) {
                    toast.error('Please enter OTP to confirm action.');
                    return;
                }
                mutation.mutate({otp: getOtpValue()});
                clearModal();
            },
            onCancel: () => {
                clearModal();
            },
        })
    }

    return <div>
        <p className={cn("font-medium mb-2", enabled ? 'text-green-600' : 'text-red-600')}>
            Two-Factor Authentication is {enabled ? 'currently' : 'not'} enabled.
        </p>
        <Button secondary={!enabled} onClick={handleAction}>
            {enabled ? 'Disable' : 'Enable'} Two-Factor Authentication
        </Button>
    </div>
}


export default Activate2FA;