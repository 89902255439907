import React, {useState} from 'react';
import TextInput from "./TextInput";
import Button from "./Button";
import {useMutation, useQueryClient} from "react-query";
import {apollo} from "../utils/apollo";
import {VERIFY_2FA} from "../utils/graphql";
import {toast} from "react-toastify";

function Verify2FA({afterValidation}) {
    const [otp, setOtp] = useState("");
    const queryClient = useQueryClient()

    const verify2FaMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: VERIFY_2FA,
                variables: {
                    otp
                },
            });
            return data?.verify2FA;
        },
        onSuccess: (data) => {
            if (data?.success && data?.message) {
                toast.success(data?.message);
                afterValidation()
            } else if (!data?.success && data?.message) {
                toast.error(data?.message);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries('is_2fa_enabled')
            setOtp('')
        },
    })

    const handleVerify2FA = () => {
        verify2FaMutation.mutate();
    }

    return (
        <div className="flex flex-col gap-3 mt-5">
            <TextInput name="2fa-otp"
                       label="Enter OTP to validate Two Factor Authentication"
                       id="2fa-otp" type="text"
                       get={otp}
                       set={value => setOtp(value)}/>

            <Button onClick={handleVerify2FA}>Validate OTP</Button>
        </div>
    );
}

export default Verify2FA;